@import "../../../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

// Setting min-height breaks align-items: center in IE11
// Workaround: https://github.com/philipwalton/flexbugs/issues/231

$name: '.Hero';

#{$name} {
    height: 80vh;
    max-height: 480px;
    position: relative;
    width: 100%;
    overflow: hidden;
    // Hero on Index should be bigger
    // Moved the code last, it ruined Intellisense...
    &-isExperienceEditor {
        width: 100%;
        padding-bottom: 0px !important;
    }

    &::before {
        content: "";
        height: 20%;
        width: 100%;
        position: absolute;
        z-index: 1; //z-index
        top: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        position: relative;
        justify-content: center;
        padding: 2rem;
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 2rem 0;

        &-content-right {
            @include media-breakpoint-up(md) {
                margin-left: auto;
                //max-width: 50%;
                text-align: right;
                align-items: flex-end;
            }
        }

        &-content-left {
            @include media-breakpoint-up(md) {
                margin-right: auto;
                //max-width: 50%;
                align-items: flex-start;
            }
        }

        &-content-center {
            @include media-breakpoint-up(md) {
                margin-left: auto;
                margin-right: auto;
                //max-width: 50%;
                text-align: center;
                align-items: center;
            }
        }
    }

    &-placeholderTop {
        position: relative;
        z-index: 2;
        padding: 0;
    }

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        min-height: 100%;
        background-size: cover;
        background-position: 50%;
    }

    &-title {
        margin: 0;
        line-height: 1;
        font-size: 3rem;
        color: $white;
        width: 100%;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 4rem;
        }
    }

    &-intro {
        font-family: $headings-font-family;
        font-size: 2rem;
        color: $white;
    }

    &-video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background-size: cover;
        object-fit: cover;
        transform: translate(-50%,-50%);
    }

    &-btnDown {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 3rem;
        border: 0;
        text-align: center;
        z-index: 10;
        background-color: transparent;
        color: $white;
        animation-name: bounce;
        animation-duration: 1s;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier( .5, 0.05, 1, .5);
        animation-iteration-count: infinite;

        &:hover {
            cursor: pointer;
        }

        &Icon {
            height: 2rem;
            color: $white;
            width: auto;
        }
    }
}

@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 1rem, 0);
    }
}

// Index Hero bigger than rest of the site
body.startPage #{$name} {
    height: calc(100vh - 5rem);
    max-height: none;

    @include media-breakpoint-up(xl) {
        height: 100vh;
    }

    #{$name}-btnDown {
        display: block;
    }
}

html {
    scroll-behavior: smooth;
}
